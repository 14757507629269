export const data = [
  {
    id: 1,
    step: "Section 1 of 6",
    title: "your info",
  },
  {
    id: 2,
    step: "Section 2 of 6",
    title: "Advisory",
  },
  {
    id: 3,
    step: "Section 3 of 6",
    title: "Client Information",
  },
  {
    id: 4,
    step: "Section 4 of 6",
    title: "Guardian Details for Minor",
  },
  {
    id: 5,
    step: "Section 5 of 6",
    title: "Accounts Details",
  },
  {
    id: 6,
    step: "Section 6 of 6",
    title: "summary",
  },
];
