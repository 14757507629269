import React, { useEffect, useState } from "react";
import { FileUpload } from 'primereact/fileupload';
import { Checkbox } from 'primereact/checkbox';
//import SweetAlert from 'sweetalert2'
import axios from 'axios';
import "primereact/resources/themes/lara-light-cyan/theme.css";

export default function Documents({ currentStep, setCurrentStep }) {

    const customBase64Uploader = async (event) => {
        // convert file to base64 encoded

        console.log(event);
        const file = event.files;
        console.log('selectedCategories', selectedCategories);
        console.log(file);
        const formData = new FormData();
        // eslint-disable-next-line
        file.map((file) => {
            formData.append('files[]', file);
        });

        // eslint-disable-next-line
        selectedCategories.map((doclist) => {
            formData.append('documents[]', doclist.name);
        });

        formData.append("file", file);
        formData.append("action", 'client_onboarding_docs_uploads');
        formData.append("token", token);
        formData.append("type", 'formData');
        const resp = await axios.post("https://lmslive.finnovate.in/php/appapi.php", formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        if(resp.data.status === 100){
            sessionStorage.removeItem("token");
            setCurrentStep(currentStep + 1);
        }

        console.log(resp);

    };

    const [token, setToken] = useState();

    useEffect(() => {

        if (sessionStorage.getItem('token') !== null) {
            setToken(sessionStorage.getItem('token'));

            axios.post(`https://lmslive.finnovate.in/php/appapi.php`, { "id": sessionStorage.getItem('token'), action: 'getOnboardingData' })
                .then(function (response) {

                    if (response.data.status === 100) {



                    }

                });
        }

    }, []);


    const categories = [
        { name: 'Aadhaar  - Front & Back', key: 'Aadhaar  - Front & Back' },
        { name: 'Pan Card', key: 'Pan Card' },
        { name: 'Photo', key: 'Photo' },
        { name: 'Cancelled Cheque', key: 'Cancelled Cheque' },
        { name: 'Signature', key: 'Signature' }
    ];

    const [selectedCategories, setSelectedCategories] = useState([categories[0], categories[1], categories[2], categories[3], categories[4]]);
    const onCategoryChange = (e) => {
        let _selectedCategories = [...selectedCategories];

        if (e.checked)
            _selectedCategories.push(e.value);
        else
            _selectedCategories = _selectedCategories.filter(category => category.key !== e.value.key);

        setSelectedCategories(_selectedCategories);
    };

    return (
        <>

            <div>
                <h1 className="text-primary-marineBlue font-bold text-[1.6rem] md:text-4xl leading-9">
                    Documents
                </h1>
                <h3 className="text-gray-400 mt-2">
                    Please provide your name, email address, and phone number.
                </h3>

                <div className="md:mt-7 mt-5 space-y-4">

                    <div className="flex flex-col gap-1">
                        <div className="flex">
                            <div className="mr-auto">
                                <label className="text-primary-marineBlue font-medium" htmlFor="name">Documents:</label>
                            </div>
                        </div>
                        {categories.map((category) => {
                            return (
                                <div key={category.key} className="flex align-items-center">
                                    <Checkbox inputId={category.key} name="category" value={category} onChange={onCategoryChange} checked={selectedCategories.some((item) => item.key === category.key)} />
                                    <label htmlFor={category.key} className="ml-2">
                                        {category.name}
                                    </label>
                                </div>
                            );
                        })}


                    </div>


                    <div className="card flex justify-content-center">


                        <FileUpload name="demo[]" url={'/api/upload'} multiple accept="image/*, application/pdf" maxFileSize={1000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} customUpload uploadHandler={customBase64Uploader} />

                    </div>


                </div>
            </div>

            <footer className="md:block hidden w-full p-3 left-0 right-0 bottom-0">
                <div className="flex">
                    <div className="mr-auto">




                    </div>
                    <div className="text-right">

                       

                    </div>
                </div>
            </footer>
        </>
    );
}
