import React from "react";
//import Sidebar from "./components/sidebar";
//import Main from "./components/main";

import RouterPage from "./RouterPage";

const App = () => {
  return (
    <>

      <React.Fragment>

        <RouterPage />

      </React.Fragment >

      {/*<main className="bg-secondary-mongolia relative h-screen md:overflow-scroll overflow-y-scroll md:flex items-center justify-center font-ubuntu">

        <div className="md:bg-white bg-transparent rounded-xl absolute md:relative p-4 flex md:flex-row flex-col md:max-h-[100%] md:max-w-[900px] h-full w-full">

          <Sidebar />
          <Main />
        </div>
      </main>*/}
    </>
  );
};

export default App;
