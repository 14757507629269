import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';

import { InputNumber } from 'primereact/inputnumber';

import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import SweetAlert from 'sweetalert2'
import axios from 'axios';
import "primereact/resources/themes/lara-light-cyan/theme.css";

export default function ClientInformation({ currentStep, setCurrentStep }) {

    const toast = useRef(null);

    const defaultValues = {
        client_name: '',
        email_id: '',
        phone_no: '',
        address: '',
        gender: '',
        marital_status: '',
        birth_place: '',
        annual_income: '',
        occupation: '',
        business_name: '',
        residential_status: '',
        monther_name: '',
        name_of_nominee: '',
        nominee_relation: '',
        nominee_dob: '',
        is_minor: '',
        other_occupation: '',
        other_address: '',
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({ defaultValues });

    const [token, setToken] = useState();

    useEffect(() => {

        if (sessionStorage.getItem('token') !== null) {
            setToken(sessionStorage.getItem('token'));

            axios.post(`https://lmslive.finnovate.in/php/appapi.php`, { "id": sessionStorage.getItem('token'), action: 'getOnboardingData' })
                .then(function (response) {

                    if (response.data.status === 100) {

                        reset({
                            client_name: response.data.rows.client_name,
                            email_id: response.data.rows.email_id,
                            phone_no: response.data.rows.phone_no,
                            address: response.data.rows.address,
                            gender: response.data.rows.gender,
                            marital_status: response.data.rows.marital_status,
                            birth_place: response.data.rows.birth_place,
                            annual_income: response.data.rows.annual_income,
                            occupation: response.data.rows.occupation,
                            business_name: response.data.rows.business_name,
                            residential_status: response.data.rows.residential_status,
                            monther_name: response.data.rows.monther_name,
                            name_of_nominee: response.data.rows.name_of_nominee,
                            nominee_relation: response.data.rows.nominee_relation,
                            nominee_dob: new Date(response.data.rows.nominee_dob),
                            is_minor: response.data.rows.is_minor,
                            other_occupation: response.data.rows.occupation === "Other" ? response.data.rows.other_occupation : null,
                            other_address: response.data.rows.address === "2" ? response.data.rows.other_address : null
                        });


                        setAddressType(response.data.rows.address);
                        setOccupationType(response.data.rows.occupation);

                    }

                });
        }

    }, [reset]);

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onSubmit = (data) => {

        console.log(data);
        //data.rm_name && show(data);


        const PostData = {
            client_name: data.client_name,
            email_id: data.email_id,
            phone_no: data.phone_no,
            address: data.address,
            gender: data.gender,
            marital_status: data.marital_status,
            birth_place: data.birth_place,
            annual_income: data.annual_income,
            occupation: data.occupation,
            business_name: data.business_name,
            residential_status: data.residential_status,
            monther_name: data.monther_name,
            name_of_nominee: data.name_of_nominee,
            nominee_relation: data.nominee_relation,
            nominee_dob: data.nominee_dob,
            is_minor: data.is_minor,
            other_occupation: data.other_occupation,
            other_address: data.other_address,
            token: token,
            step: 3,
            action: 'client_onboarding'
        };


        axios.post(`https://lmslive.finnovate.in/php/appapi.php`, PostData)
            .then(function (response) {

                sessionStorage.setItem('token', response.data.token);
                setToken(response.data.token);
                console.log(response.data);

                if (data.is_minor === "No") {
                    setCurrentStep(currentStep + 2);
                } else {
                    setCurrentStep(currentStep + 1);
                }


            }).catch(function (error) {
                SweetAlert.fire({
                    toast: true,
                    icon: 'error',
                    title: error,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                });
            });

        reset();
        return;
    };

    const AddressOtp = [
        { name: 'Same as Aadhaar', key: '1' },
        { name: 'Other', key: '2' },
    ];

    const GenderOtp = [
        { name: 'Male', key: 'Male' },
        { name: 'Female', key: 'Female' },
    ];

    const MaritalOtp = [
        { name: 'Single', key: 'Single' },
        { name: 'Married', key: 'Married' },
        { name: 'Other', key: 'Other' },
    ];

    const OccupationOtp = [
        { name: 'Doctor', key: 'Doctor' },
        { name: 'Corporate', key: 'Corporate' },
        { name: 'Other', key: 'Other' },
    ];

    const ResidentialOtp = [
        { name: 'Resident Indian', key: 'Resident Indian' },
        { name: 'NRE', key: 'NRE' },
        { name: 'NRO', key: 'NRO' },
    ];

    const MinorOtp = [
        { name: 'Yes', key: 'Yes' },
        { name: 'No', key: 'No' },
    ];

    const [addressType, setAddressType] = useState();
    const [occupationType, setOccupationType] = useState();

    const goBack = (e) => {
        e.preventDefault();

        setCurrentStep(currentStep - 1);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <h1 className="text-primary-marineBlue font-bold text-[1.6rem] md:text-4xl leading-9">
                        Client Information
                    </h1>
                    <h3 className="text-gray-400 mt-2">
                        Please provide your name, email address, and phone number.
                    </h3>

                    <div className="md:mt-7 mt-5 space-y-4">




                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="client_name">Client Name:</label>
                                </div>
                            </div>
                            <Toast ref={toast} />
                            <Controller
                                name="client_name"
                                control={control}
                                rules={{ required: 'Client Name is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />

                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="email_id">Email ID:</label>
                                </div>
                            </div>


                            <Controller
                                name="email_id"
                                control={control}
                                rules={{ required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="phone_no">Phone Number:</label>
                                </div>
                            </div>

                            <Controller
                                name="phone_no"
                                control={control}
                                rules={{ required: 'Mobile Number is required', pattern: { value: /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/, message: 'Invalid Mobile Number' } }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} minLength={10} maxLength={10} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="address">Address:</label>
                                </div>
                            </div>
                            <Controller
                                name="address"
                                control={control}
                                rules={{ required: 'Address is required.' }}
                                render={({ field }) => (
                                    <>

                                        {AddressOtp.map((category) => {
                                            return (
                                                <div key={category.key} className="flex align-items-center">
                                                    <RadioButton
                                                        inputId={category.key}
                                                        {...field}
                                                        inputRef={field.ref}
                                                        value={category}
                                                        onChange={(e) => { field.onChange(e.value.key); setAddressType(e.value.key); }}
                                                        checked={field.value === category.key}
                                                        pt={{
                                                            input: {
                                                                className: field.value === category.key ? 'bg-orange-500 border-orange-500' : undefined
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={category.key} className="ml-2">
                                                        {category.name}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>

                        {
                            addressType === "2" && (
                                <>
                                    <div className="flex flex-col gap-1">
                                        <div className="flex">
                                            <div className="mr-auto">
                                                <label className="text-primary-marineBlue font-medium" htmlFor="other_address">Enter Address:</label>
                                            </div>
                                        </div>
                                        <Controller
                                            name="other_address"
                                            control={control}
                                            rules={{ required: 'Address is required.' }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <span className="p-float-label">
                                                        <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                                    </span>
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </div>
                                </>
                            )
                        }



                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="gender">Gender:</label>
                                </div>
                            </div>

                            <Controller
                                name="gender"
                                control={control}
                                rules={{ required: 'Gender is required.' }}
                                render={({ field }) => (
                                    <>

                                        {GenderOtp.map((category) => {
                                            return (
                                                <div key={category.key} className="flex align-items-center">
                                                    <RadioButton
                                                        inputId={category.key}
                                                        {...field}
                                                        inputRef={field.ref}
                                                        value={category}
                                                        onChange={(e) => field.onChange(e.value.key)}
                                                        checked={field.value === category.key}
                                                        pt={{
                                                            input: {
                                                                className: field.value === category.key ? 'bg-orange-500 border-orange-500' : undefined
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={category.key} className="ml-2">
                                                        {category.name}
                                                    </label>
                                                </div>
                                            );
                                        })}

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="marital_status">Marital status:</label>
                                </div>
                            </div>

                            <Controller
                                name="marital_status"
                                control={control}
                                rules={{ required: 'Marital Status is required.' }}
                                render={({ field }) => (
                                    <>

                                        {MaritalOtp.map((category) => {
                                            return (
                                                <div key={category.key} className="flex align-items-center">
                                                    <RadioButton
                                                        inputId={category.key}
                                                        {...field}
                                                        inputRef={field.ref}
                                                        value={category}
                                                        onChange={(e) => field.onChange(e.value.key)}
                                                        checked={field.value === category.key}
                                                        pt={{
                                                            input: {
                                                                className: field.value === category.key ? 'bg-orange-500 border-orange-500' : undefined
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={category.key} className="ml-2">
                                                        {category.name}
                                                    </label>
                                                </div>
                                            );
                                        })}

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />

                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="birth_place">Birth City & Place:</label>
                                </div>
                            </div>
                            <Controller
                                name="birth_place"
                                control={control}
                                rules={{ required: 'Birth City & Place is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>

                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="annual_income">Annual Income:</label>
                                </div>
                            </div>
                            <Controller
                                name="annual_income"
                                control={control}
                                rules={{ required: 'Annual Income is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputNumber id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} locale="en-IN" minFractionDigits={0} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />

                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="occupation">Occupation:</label>
                                </div>
                            </div>

                            <Controller
                                name="occupation"
                                control={control}
                                rules={{ required: 'Occupation is required.' }}
                                render={({ field }) => (
                                    <>

                                        {OccupationOtp.map((category) => {
                                            return (
                                                <div key={category.key} className="flex align-items-center">
                                                    <RadioButton
                                                        inputId={category.key}
                                                        {...field}
                                                        inputRef={field.ref}
                                                        value={category}
                                                        onChange={(e) => { field.onChange(e.value.key); setOccupationType(e.value.key); }}
                                                        checked={field.value === category.key}
                                                        pt={{
                                                            input: {
                                                                className: field.value === category.key ? 'bg-orange-500 border-orange-500' : undefined
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={category.key} className="ml-2">
                                                        {category.name}
                                                    </label>
                                                </div>
                                            );
                                        })}

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />

                        </div>

                        {
                            occupationType === "Other" && (
                                <>
                                    <div className="flex flex-col gap-1">
                                        <div className="flex">
                                            <div className="mr-auto">
                                                <label className="text-primary-marineBlue font-medium" htmlFor="other_occupation">Enter Occupation:</label>
                                            </div>
                                        </div>
                                        <Controller
                                            name="other_occupation"
                                            control={control}
                                            rules={{ required: 'Occupation is required.' }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <span className="p-float-label">
                                                        <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                                    </span>
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </div>
                                </>
                            )
                        }

                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="business_name">Company / Business Name:</label>
                                </div>
                            </div>
                            <Controller
                                name="business_name"
                                control={control}
                                rules={{ required: 'Company / Business Name is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>

                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="residential_status">Residential Status:</label>
                                </div>
                            </div>

                            <Controller
                                name="residential_status"
                                control={control}
                                rules={{ required: 'Residential Status is required.' }}
                                render={({ field }) => (
                                    <>

                                        {ResidentialOtp.map((category) => {
                                            return (
                                                <div key={category.key} className="flex align-items-center">
                                                    <RadioButton
                                                        inputId={category.key}
                                                        {...field}
                                                        inputRef={field.ref}
                                                        value={category}
                                                        onChange={(e) => field.onChange(e.value.key)}
                                                        checked={field.value === category.key}
                                                        pt={{
                                                            input: {
                                                                className: field.value === category.key ? 'bg-orange-500 border-orange-500' : undefined
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={category.key} className="ml-2">
                                                        {category.name}
                                                    </label>
                                                </div>
                                            );
                                        })}

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />

                        </div>

                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="monther_name">Mother's Name:</label>
                                </div>
                            </div>
                            <Controller
                                name="monther_name"
                                control={control}
                                rules={{ required: 'Mother\'s Name is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="name_of_nominee">Name of Nominee:</label>
                                </div>
                            </div>
                            <Controller
                                name="name_of_nominee"
                                control={control}
                                rules={{ required: 'Name of Nominee is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="name">Nominee - Relation:</label>
                                </div>
                            </div>
                            <Controller
                                name="nominee_relation"
                                control={control}
                                rules={{ required: 'Nominee - Relation is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>



                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="nominee_dob">Nominee - Date of Birth:</label>
                                </div>
                            </div>

                            <Controller
                                name="nominee_dob"
                                control={control}
                                rules={{ required: 'Nominee - Date of Birth is required.' }}
                                render={({ field, fieldState }) => (

                                    <>
                                        <Calendar inputId={field.name} value={field.value} onChange={field.onChange} dateFormat="dd/mm/yy" className={classNames({ 'p-invalid': fieldState.error })} />
                                        {getFormErrorMessage(field.name)}
                                        {console.log(field.value)}
                                    </>
                                )}
                            />

                        </div>




                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="is_minor">Is Nominee a Minor ?</label>
                                </div>
                            </div>


                            <Controller
                                name="is_minor"
                                control={control}
                                rules={{ required: 'Is Nominee a Minor is required.' }}
                                render={({ field }) => (
                                    <>

                                        {MinorOtp.map((category) => {
                                            return (
                                                <div key={category.key} className="flex align-items-center">
                                                    <RadioButton
                                                        inputId={category.key}
                                                        {...field}
                                                        inputRef={field.ref}
                                                        value={category}
                                                        onChange={(e) => field.onChange(e.value.key)}
                                                        checked={field.value === category.key}
                                                        pt={{
                                                            input: {
                                                                className: field.value === category.key ? 'bg-orange-500 border-orange-500' : undefined
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={category.key} className="ml-2">
                                                        {category.name}
                                                    </label>
                                                </div>
                                            );
                                        })}

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />

                        </div>




                    </div>
                </div>

                <footer className="md:block hidden w-full p-3 left-0 right-0 bottom-0">
                    <div className="flex">
                        <div className="mr-auto">


                            <button onClick={goBack} className={`py-3 outline-none px-5 rounded-lg duration-700 bg-transparent text-gray-400 hover:text-primary-marineBlue`}>
                                {"Go Back"}
                            </button>

                        </div>
                        <div className="text-right">

                            <button type="submit" className={"py-3 outline-none px-5 rounded-lg duration-700 bg-primary-marineBlue text-white"}>
                                {"Next Step"}
                            </button>

                        </div>
                    </div>
                </footer>

            </form>
        </>
    );
}
