import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Main from './components/main'
import Dashboard from './components/Dashboard'

export default function RouterPage() {

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/add-data" element={<Main />}></Route>
                    <Route path="/dashboard" element={<Dashboard />}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
}