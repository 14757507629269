import React, { useEffect, useState } from "react";
import PersonalInfo from "../pages/personalInfo";
import Advisory from "../pages/Advisory";
import ClientInformation from "../pages/ClientInformation";
import AccountsDetails from "../pages/AccountsDetails";
import GuardianDetails from "../pages/GuardianDetails";
import Documents from "../pages/Documents";
import Sidebar from "./sidebar";
//import Plans from "../pages/plans";
//import AddOns from "../pages/addOns";
//import { GlobalContex } from "../context/globalContext";
import Button from "../utils/button";
import Summary from "../pages/summary";

const Main = () => {



  const [currentStep, setCurrentStep] = useState(1);
  // eslint-disable-next-line
  const [completed, setCompleted] = useState(false);
  const [formCompeleted, setFormCompeleted] = useState(null);

  const nextStep = (e) => {
    e.preventDefault();

    setCurrentStep(currentStep + 1);

  };

  const goBack = (e) => {
    e.preventDefault();

    setCurrentStep(currentStep - 1);
  };

  const submitForm = (e) => {
    e.preventDefault();

    setFormCompeleted(true);
  };

  useEffect(() => {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('id');

    console.log(token);

    if (token !== null || token !== undefined) {
      sessionStorage.setItem('token', token);
    }

  }, [])

  return (
    <>

        <main className="bg-secondary-mongolia relative h-screen md:overflow-scroll overflow-y-scroll md:flex items-center justify-center font-ubuntu">

          <div className="md:bg-white bg-transparent rounded-xl absolute md:relative p-4 flex md:flex-row flex-col md:max-h-[100%] md:max-w-[900px] h-full w-full">


            <Sidebar currentStep={currentStep} />

            <div className="md:min-h-full md:shadow-none mx-auto md:m-0 rounded-xl md:rounded-none md:w-full w-[100%] md:bg-transparent min-h-[400px] bg-white z-10 mt-[84px]">

              <div
                action="#"
                className="md:mx-16 md:my-0 mx-6 my-6 py-0 md:py-10 relative h-full"
              >
                {currentStep === 1 ? <PersonalInfo currentStep={currentStep} setCurrentStep={setCurrentStep} /> : null}
                {currentStep === 2 ? <Advisory currentStep={currentStep} setCurrentStep={setCurrentStep} /> : null}
                {currentStep === 3 ? <ClientInformation currentStep={currentStep} setCurrentStep={setCurrentStep} /> : null}
                {currentStep === 4 ? <GuardianDetails currentStep={currentStep} setCurrentStep={setCurrentStep} /> : null}
                {currentStep === 5 ? <AccountsDetails currentStep={currentStep} setCurrentStep={setCurrentStep} /> : null}
                {currentStep === 6 ? <Documents currentStep={currentStep} setCurrentStep={setCurrentStep}/> : null}
                {currentStep === 7 ? <Summary /> : null}

                {formCompeleted ? null : (
                  <footer className="fixed bg-white md:hidden block w-full p-3 left-0 right-0 bottom-0">
                    <div className="flex">
                      <div className="mr-auto">
                        {completed ? (
                          <Button
                            text={"Go Back"}
                            onClick={goBack}
                            className={
                              "bg-transparent text-gray-400 hover:text-primary-marineBlue"
                            }
                          />
                        ) : null}
                      </div>
                      <div className="text-right">
                        <Button
                          text={currentStep === 4 ? "Confirm" : "Next Step1"}
                          onClick={currentStep === 4 ? submitForm : nextStep}
                          className={
                            currentStep === 4
                              ? "bg-primary-purplishBlue text-white"
                              : "bg-primary-marineBlue text-white"
                          }
                        />
                      </div>
                    </div>
                  </footer>
                )}
              </div>
            </div>
          </div>
        </main>
    </>
  );
};

export default Main;
