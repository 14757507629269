import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import SweetAlert from 'sweetalert2'
import axios from 'axios';
import "primereact/resources/themes/lara-light-cyan/theme.css";

export default function GuardianDetails({ currentStep, setCurrentStep }) {

    const toast = useRef(null);



    const defaultValues = {
        guardian_name: '',
        guardian_dob: '',
        guardian_relation: ''
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({ defaultValues });

    const [token, setToken] = useState();

    useEffect(() => {

        if (sessionStorage.getItem('token') !== null) {
            setToken(sessionStorage.getItem('token'));

            axios.post(`https://lmslive.finnovate.in/php/appapi.php`, { "id": sessionStorage.getItem('token'), action: 'getOnboardingData' })
                .then(function (response) {

                    if (response.data.status === 100) {

                        reset({
                            guardian_name: response.data.rows.guardian_name,
                            guardian_dob: new Date(response.data.rows.guardian_dob),
                            guardian_relation: response.data.rows.guardian_relation
                        });

                    }

                });
        }

    }, [reset]);

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onSubmit = (data) => {

        console.log(data);
        //data.rm_name && show(data);


        const PostData = {
            guardian_relation: data.guardian_relation,
            guardian_name: data.guardian_name,
            guardian_dob: data.guardian_dob,
            token: token,
            step: 4,
            action: 'client_onboarding'
        };


        axios.post(`https://lmslive.finnovate.in/php/appapi.php`, PostData)
            .then(function (response) {

                sessionStorage.setItem('token', response.data.token);
                setToken(response.data.token);
                console.log(response.data);
                setCurrentStep(currentStep + 1);

            }).catch(function (error) {
                SweetAlert.fire({
                    toast: true,
                    icon: 'error',
                    title: error,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                });
            });

        reset();
        return;
    };

    const goBack = (e) => {
        e.preventDefault();
    
        setCurrentStep(currentStep - 1);
      };
      
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <h1 className="text-primary-marineBlue font-bold text-[1.6rem] md:text-4xl leading-9">
                        Guardian Details for Minor
                    </h1>
                    <h3 className="text-gray-400 mt-2">
                        Please provide your name, email address, and phone number.
                    </h3>

                    <div className="md:mt-7 mt-5 space-y-4">




                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="guardian_name">Guardian Name:</label>
                                </div>
                            </div>
                            <Toast ref={toast} />
                            <Controller
                                name="guardian_name"
                                control={control}
                                rules={{ required: 'Guardian Name is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="guardian_dob">Guardian DOB:</label>
                                </div>
                            </div>
                            <Controller
                                name="guardian_dob"
                                control={control}
                                rules={{ required: 'Guardian - Date of Birth is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Calendar inputId={field.name} value={field.value} onChange={field.onChange} dateFormat="dd/mm/yy" className={classNames({ 'p-invalid': fieldState.error })} />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="guardian_relation">Guardian Relationship:</label>
                                </div>
                            </div>
                            <Toast ref={toast} />
                            <Controller
                                name="guardian_relation"
                                control={control}
                                rules={{ required: 'Guardian Relationship is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>


                    </div>
                </div>

                <footer className="md:block hidden w-full p-3 left-0 right-0 bottom-0">
                    <div className="flex">
                        <div className="mr-auto">


                        <button onClick={goBack} className={`py-3 outline-none px-5 rounded-lg duration-700 bg-transparent text-gray-400 hover:text-primary-marineBlue`}>
                                {"Go Back"}
                            </button>

                        </div>
                        <div className="text-right">

                            <button type="submit" className={"py-3 outline-none px-5 rounded-lg duration-700 bg-primary-marineBlue text-white"}>
                                {"Next Step"}
                            </button>

                        </div>
                    </div>
                </footer>
            </form>
        </>
    );
}
