import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
//import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
//import { classNames } from 'primereact/utils';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import SweetAlert from 'sweetalert2'
import axios from 'axios';

const PersonalInfo = ({ currentStep, setCurrentStep }) => {


  const toast = useRef(null);

  /*const show = (data) => {
    toast.current.show({ severity: 'success', summary: 'Form Submitted', detail: `${data.rm_name.name}` });

  };*/

  const defaultValues = {
    rm_name: '',
    category: ''
  };

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({ defaultValues });

  const [token, setToken] = useState();

  const onSubmit = (data) => {

    console.log(data);

    const PostData = {
      rm_name: data.rm_name,
      product: data.category,
      token: token,
      step: 1,
      action: 'client_onboarding'
    };


    axios.post(`https://lmslive.finnovate.in/php/appapi.php`, PostData)
      .then(function (response) {

        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
        console.log('token', response.data.token);

        if (data.category === "Distribution Client") {
          setCurrentStep(currentStep + 2);
        } else {
          setCurrentStep(currentStep + 1);
        }


      }).catch(function (error) {
        SweetAlert.fire({
          toast: true,
          icon: 'error',
          title: error,
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
          }
        });
      });

    reset();
    return;

  };

  useEffect(() => {

    if (sessionStorage.getItem('token') !== "null") {
      setToken(sessionStorage.getItem('token'));

      axios.post(`https://lmslive.finnovate.in/php/appapi.php`, { "id": sessionStorage.getItem('token'), action: 'getOnboardingData' })
        .then(function (response) {


          if (response.data.status === 100) {

            reset({
              rm_name: response.data.rows.rm_name,
              category: response.data.rows.product
            });

            console.log({
              rm_name: response.data.rows.rm_name,
              category: response.data.rows.product
            });
          }

        });

    }

  }, [reset]);

  const getFormErrorMessage = (name) => {
    return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
  };

  const categories = [
    { name: 'Advisory Client', key: 'Advisory Client' },
    { name: 'Distribution Client', key: 'Distribution Client' }
  ];
  //const [selectedCategory, setSelectedCategory] = useState(categories[1]);


  const countries = [
    { name: 'Jignesh Gajjar', code: 'Jignesh Gajjar' },
    { name: 'Rekha Mohnot', code: 'Rekha Mohnot' },
    { name: 'Artish More', code: 'Artish More' },
    { name: 'Sushil Asrani', code: 'Sushil Asrani' },
    { name: 'Vandana Manwani', code: 'Vandana Manwani' },
    { name: 'Avinash Tiwari', code: 'Avinash Tiwari' },
    { name: 'Rahul Meher', code: 'Rahul Meher' },
    { name: 'Vijay Pandey', code: 'Vijay Pandey' },
    { name: 'Anchal Kaushal', code: 'Anchal Kaushal' },
    { name: 'Reetesh Jaisinghani', code: 'Reetesh Jaisinghani' }
  ];


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} >
        <div>

          <h1 className="text-primary-marineBlue font-bold text-[1.6rem] md:text-4xl leading-9">
            Client Onboarding Form

          </h1>
          <h3 className="text-gray-400 mt-2">
            Please provide your name, email address, and phone number.
          </h3>

          <div className="md:mt-7 mt-5 space-y-4">



            <div className="flex flex-col gap-1">
              <div className="flex">
                <div className="mr-auto">
                  <label className="text-primary-marineBlue font-medium" htmlFor="rm_name">RM Name:</label>
                </div>
              </div>


              <select name="rm_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" {...register("rm_name", { required: 'Name is required' })}>
                <option value={""}>Choose a RM</option>

                {
                  countries.map((category) => {
                    return (
                      <option value={category.name}>{category.code}</option>
                    )

                  })}
              </select>
              <span className="text-red-800"> {errors.rm_name && errors.rm_name.message}</span>

              {/*<Controller
                name="rm_name"
                control={control}
                rules={{ required: 'RM Name required.' }}
                render={({ field, fieldState }) => (
                  <Dropdown
                    id={field.name}
                    value={"Jignesh Gajjar"}
                    optionLabel="name"
                    placeholder="Select RM Name"
                    options={countries}
                    focusInputRef={field.ref}
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              /> */}

              {/*getFormErrorMessage('rm_name')*/}


            </div>


            <div className="flex flex-col gap-1">

              <div className="flex">
                <div className="mr-auto">
                  <label className="text-primary-marineBlue font-medium" htmlFor="category">Product:</label>
                </div>
              </div>

              <Toast ref={toast} />
              <Controller
                name="category"
                control={control}
                rules={{ required: 'Product is required.' }}
                render={({ field }) => (
                  <>

                    {categories.map((category) => {


                      return (
                        <div key={category.key} className="flex align-items-center">
                          <RadioButton
                            inputId={category.key}
                            {...field}
                            inputRef={field.ref}
                            value={category}
                            onChange={(e) => field.onChange(e.value.name)}
                            checked={field.value === category.key}
                            pt={{
                              input: {
                                className: field.value === category.key ? 'bg-orange-500 border-orange-500' : undefined
                              }
                            }}
                          />
                          <label htmlFor={category.key} className="ml-2">
                            {category.name}
                          </label>
                        </div>
                      );
                    })}

                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>

          </div>
        </div>


        <footer className="md:block hidden w-full p-3 left-0 right-0 bottom-0">
          <div className="flex">
            <div className="mr-auto">




            </div>
            <div className="text-right">

              <button type="submit" className={"py-3 outline-none px-5 rounded-lg duration-700 bg-primary-marineBlue text-white"}>
                {"Next Step"}
              </button>

            </div>
          </div>
        </footer>


      </form>
    </>
  );
};

export default PersonalInfo;
