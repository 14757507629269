import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import SweetAlert from 'sweetalert2'
import axios from 'axios';
import "primereact/resources/themes/lara-light-cyan/theme.css";

export default function AccountsDetails({ currentStep, setCurrentStep }) {

    const toast = useRef(null);

    const defaultValues = {
        account_holding_type: '',
        account_number: '',
        account_ifsc: '',
        account_type: ''
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({ defaultValues });

    const [token, setToken] = useState();

    useEffect(() => {

        if (sessionStorage.getItem('token') !== null) {
            setToken(sessionStorage.getItem('token'));

            axios.post(`https://lmslive.finnovate.in/php/appapi.php`, { "id": sessionStorage.getItem('token'), action: 'getOnboardingData' })
                .then(function (response) {

                    if (response.data.status === 100) {

                        reset({
                            account_holding_type: response.data.rows.account_holding_type,
                            account_number: response.data.rows.account_number,
                            account_ifsc: response.data.rows.account_ifsc,
                            account_type: response.data.rows.account_type
                        });

                    }

                });
        }

    }, [reset]);

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onSubmit = (data) => {

        const PostData = {
            account_number: data.account_number,
            account_ifsc: data.account_ifsc,
            account_holding_type: data.account_holding_type,
            account_type: data.account_type,
            token: token,
            step: 5,
            action: 'client_onboarding'
        };


        axios.post(`https://lmslive.finnovate.in/php/appapi.php`, PostData)
            .then(function (response) {

                sessionStorage.setItem('token', response.data.token);
                setToken(response.data.token);
                console.log(response.data);
                setCurrentStep(currentStep + 1);

            }).catch(function (error) {
                SweetAlert.fire({
                    toast: true,
                    icon: 'error',
                    title: error,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                });
            });

        reset();
        return;
    };

    const AccHoldingOpt = [
        { name: 'Single', key: 'Single' },
        { name: 'Joint', key: 'Joint' },
        { name: 'Anyone/Survivor', key: 'Anyone/Survivor' },
    ];


    const AccTypeOpt = [
        { name: 'Saving', key: 'Saving' },
        { name: 'Current', key: 'Current' },
        { name: 'Other', key: 'Other' },
    ];

    const goBack = (e) => {
        e.preventDefault();
    
        setCurrentStep(currentStep - 1);
      };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <h1 className="text-primary-marineBlue font-bold text-[1.6rem] md:text-4xl leading-9">
                        Accounts Details

                    </h1>
                    <h3 className="text-gray-400 mt-2">
                        Please provide your name, email address, and phone number.
                    </h3>

                    <div className="md:mt-7 mt-5 space-y-4">


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="account_holding_type">Account Holding Type:</label>
                                </div>
                            </div>

                            <Toast ref={toast} />
                            <Controller
                                name="account_holding_type"
                                control={control}
                                rules={{ required: 'Account Holding Type is required.' }}
                                render={({ field }) => (
                                    <>

                                        {AccHoldingOpt.map((category) => {
                                            return (
                                                <div key={category.key} className="flex align-items-center">
                                                    <RadioButton
                                                        inputId={category.key}
                                                        {...field}
                                                        inputRef={field.ref}
                                                        value={category}
                                                        onChange={(e) => field.onChange(e.value.key)}
                                                        checked={field.value === category.key}
                                                        pt={{
                                                            input: {
                                                                className: field.value === category.key ? 'bg-orange-500 border-orange-500' : undefined
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={category.key} className="ml-2">
                                                        {category.name}
                                                    </label>
                                                </div>
                                            );
                                        })}

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>



                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="account_number">Bank Details - Account Number:</label><br />
                                    <span style={{ 'font-size': '11px' }}>NA if cancel cheque provided</span>
                                </div>
                            </div>
                            <Controller
                                name="account_number"
                                control={control}
                                rules={{ required: 'Account Number is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="account_ifsc">Bank Details - IFSC Code:</label><br />
                                    <span style={{ 'font-size': '11px' }}>NA if cancel cheque provided</span>
                                </div>
                            </div>
                            <Controller
                                name="account_ifsc"
                                control={control}
                                rules={{ required: 'IFSC Code is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>


                        <div className="flex flex-col gap-1">
                            <div className="flex">
                                <div className="mr-auto">
                                    <label className="text-primary-marineBlue font-medium" htmlFor="account_type">Bank Details - Account Type:</label>
                                </div>
                            </div>


                            <Controller
                                name="account_type"
                                control={control}
                                rules={{ required: 'Account Type is required.' }}
                                render={({ field }) => (
                                    <>

                                        {AccTypeOpt.map((category) => {
                                            return (
                                                <div key={category.key} className="flex align-items-center">
                                                    <RadioButton
                                                        inputId={category.key}
                                                        {...field}
                                                        inputRef={field.ref}
                                                        value={category}
                                                        onChange={(e) => field.onChange(e.value.key)}
                                                        checked={field.value === category.key}
                                                        pt={{
                                                            input: {
                                                                className: field.value === category.key ? 'bg-orange-500 border-orange-500' : undefined
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={category.key} className="ml-2">
                                                        {category.name}
                                                    </label>
                                                </div>
                                            );
                                        })}

                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>



                    </div>
                </div>

                <footer className="md:block hidden w-full p-3 left-0 right-0 bottom-0">
                    <div className="flex">
                        <div className="mr-auto">


                        <button onClick={goBack} className={`py-3 outline-none px-5 rounded-lg duration-700 bg-transparent text-gray-400 hover:text-primary-marineBlue`}>
                                {"Go Back"}
                            </button>

                        </div>
                        <div className="text-right">

                            <button type="submit" className={"py-3 outline-none px-5 rounded-lg duration-700 bg-primary-marineBlue text-white"}>
                                {"Next Step"}
                            </button>

                        </div>
                    </div>
                </footer>

            </form>
        </>
    );
}
