import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Button } from 'primereact/button';

export default function Dashboard() {
    const [products, setProducts] = useState([]);

    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;
    useEffect(() => {

        axios.post(`https://lmslive.finnovate.in/php/appapi.php`, { action: 'getOnboardingData', type: 'all' })
            .then(function (response) {


                if (response.data.status === 100) {

                    setProducts(response.data.rows);

                }

            });

    }, []);

    // eslint-disable-next-line
    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const onRowSelect = (event) => {

        if (event.data.id) {
            sessionStorage.setItem('token', event.data.id);
            window.location.href = "add-data?id=" + event.data.id;
        }
    };

    const onRowUnselect = (event) => {
        console.log(`Name: ${event.data.id}`);
    };

    const redirectOnBoard = (id) => {

        console.log(id);
        //window.location.href = '?id='+id
    }
    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold">Client Onboarding Lists</span>
        </div>
    );
    const footer = `In total there are ${products ? products.length : 0} products.`;

    const addData = () =>{

        sessionStorage.removeItem('token');
        window.location.href = 'add-data';
    }
    
    return (
        <main className="page p-5">
            

            <div className="flex">
            <div className="mr-auto">




            </div>
            <div className="text-right">

              <button type="button" className={"py-3 outline-none px-5 rounded-lg duration-700 bg-primary-marineBlue text-white"} onClick={addData}>
                {"Add New Onboarding Data"}
              </button>

            </div>
          </div>
          <br/>
            <div className="card">
                <DataTable selectionMode="single" value={products} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} header={header} footer={footer} tableStyle={{ minWidth: '60rem' }} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} onRowSelect={onRowSelect} onRowUnselect={onRowUnselect}>
                    <Column field="id" sortable header="Sr.no"></Column>
                    <Column field="rm_name" sortable header="RM Name"></Column>
                    <Column field="product" sortable header="Product"></Column>
                    <Column field="plan" sortable header="Plan"></Column>
                    <Column field="client_name" sortable header="Client Name"></Column>
                    <Column field="email_id" sortable header="Email"></Column>
                    <Column field="phone_no" sortable header="Mobile"></Column>
                    <Column field="gender" header="Gender"></Column>
                    <Column field="marital_status" header="Mariatal Status"></Column>
                    <Column field="annual_income" header="Annual Income"></Column>
                    <Column field="residential_status" header="Res Status"></Column>
                    <Column field="is_minor" header="Is Minor"></Column>
                    <Column field="step" header="Step"></Column>
                    <Column header="Action" body={(rowData) => (<p-column field="myField" header="My Field">
                        <ng-template let-row="rowData" pTemplate="body" onClick={redirectOnBoard(rowData.id)}>
                            Click me
                        </ng-template>
                    </p-column>)}></Column>
                </DataTable>
            </div>
        </main>
    );
}