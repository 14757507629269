import React from "react";
import check from "../assets/images/icon-thank-you.svg";

const Summary = () => {

  return (
    <>

        <div className="h-full flex justify-center items-center">
          <div className="text-center">
            <div className="flex items-center justify-center">
              <img src={check} alt="check svg" />
            </div>
            <div className="mt-5">
              <h1 className="text-primary-marineBlue font-bold text-[1.6rem] md:text-4xl leading-9">
                Thank you!
              </h1>
              <p className="mt-5 text-gray-400">
                Thanks for uploading Client Onboarding data. If you ever need support, please feel free to email us at support@finnovate.in.
              </p>
            </div>
          </div>
        </div>

    </>
  );
};

export default Summary;
